import { useEffect, useState } from "react";
import sx from "./Whats.module.css";
import { BsWhatsapp } from "react-icons/bs";

function Whats() {
  const [pulsing, setPulsing] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setPulsing((pulsing) => !pulsing);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={sx.footer}>
      <div className={sx.text}>
        <h2>Tire sua dúvida!!</h2>
      </div>
      <a
        href="https://wa.me/5547997890401?text=Olá%20tenho%20interesse%20em%20saber%20mais%20sobre%20as%20abelhas!!"
        target="_blank"
      >
        <div className={`${sx.card} ${pulsing ? sx.pulse : ""} `}>
          <BsWhatsapp
            style={{
              color: "green",
              fontSize: "calc(60px + 3vmin)",
            }}
          />
        </div>
      </a>
    </div>
  );
}

export default Whats;
