import sx from "./Maps.module.css";

function Maps() {
  return (
    <div className={sx.maps}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d420.1828228893557!2d-48.990252947072385!3d-27.062888076888537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94df3f62822b914f%3A0xcff50d60dc4253ba!2sMelipon%C3%A1rio%20Lenoir%20Rieg!5e1!3m2!1spt-BR!2sbr!4v1694028664772!5m2!1spt-BR!2sbr"
        height="300px"
        width="100%"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Maps