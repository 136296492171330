import { useEffect, useState } from "react";
import sx from "./Navbar.module.css";

function Navbar() {
  const [text, setText] = useState("");
  const phrases = ["VENHA CONHECER!!"];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [typingDirection, setTypingDirection] = useState("typing");

  useEffect(() => {
    const interval = setInterval(() => {
      const currentText = phrases[currentPhraseIndex];
      if (typingDirection === "typing") {
        setText((prevText) => currentText.substring(0, prevText.length + 1));
        if (text === currentText) {
          setTypingDirection("deleting");
          clearInterval(interval);
        }
      } else if (typingDirection === "deleting") {
        setText((prevText) => prevText.substring(0, prevText.length - 1));
        if (text === "") {
          setTypingDirection("typing");
          setCurrentPhraseIndex(
            (prevIndex) => (prevIndex + 1) % phrases.length
          );
          clearInterval(interval);
        }
      }
    }, 150);

    return () => clearInterval(interval);
  }, [text, typingDirection, currentPhraseIndex, phrases]);

  return (
    <header className={sx.header}>
      <h1>
        SEJA <span>BEM VINDO!</span>
      </h1>
      <div className={sx.text}><span>{text}</span></div>
    </header>
  );
}

export default Navbar;
