import sx from "./Products.module.css";
import Cardproducts from "../elements/Cardproducts.js";
import caixaabelha from "../../images/caixaabelha.png";
import isca from "../../images/isca.png";
import propolis from "../../images/propolis.jpg";

function Products() {
  return (
    <div className={sx.products}>
      <div className={sx.container} >
        <div className={sx.texts}>
          <span>Produtos</span>
          <p>Tabela de preços</p>
        </div>
        <div className={sx.cards}>
          <Cardproducts
            title="Caixa abelha"
            price="Em breve"
            img={caixaabelha}
            link="https://wa.me/5547997890401?text=Olá%20tenho%20interesse%20em%20comprar%20a%20Caixa%20de%20abelha!!"
          />
          <Cardproducts
            title="Isca + atrativo 200 ml"
            price="R$25,00"
            img={isca}
            link="https://wa.me/5547997890401?text=Olá%20tenho%20interesse%20em%20comprar%20a%20Isca!!"
          />
          <Cardproducts 
          title="Própolis" 
          price="R$20,00" 
          img={propolis}
          link="https://wa.me/5547997890401?text=Olá%20tenho%20interesse%20em%20comprar%20Própolis!!"
           />
        </div>
      </div>
    </div>
  );
}

export default Products;
