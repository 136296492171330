import sx from "./Footer.module.css";
import dayjs from "dayjs";

function Footer() {
    const year = dayjs().year()
  return (
    <div className={sx.footer}>
      <div className={sx.span}>
        <span>Meliponário Lenoir Rieg, all rigths reserved {year}</span>
      </div>
    </div>
  );
}

export default Footer;
