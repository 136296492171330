import sx from "./Services.module.css";
import Cardservice from "../elements/Cardservice";
import balao from "../../images/balao.png";
import estrela from "../../images/estrela.png";
import medalha from "../../images/medalha.png";

function Services() {
  return (
    <div className={sx.services}>
      <div className={sx.texts}>
        <span>Serviços</span>
        <p>serviços que fornecemos</p>
      </div>
      <div className={sx.cards}>
        <div >
          <Cardservice
            img={medalha}
            title="Produtos de qualidade"
            subtitle="100% artesanal"
          />
        </div>
        <div className={sx.border}></div>
        <div>
          <Cardservice
            img={estrela}
            title="Fauna e Flora"
            subtitle="Ajudando o meio ambiente"
          />
        </div>
        <div className={sx.border}></div>
        <div>
          <Cardservice
            img={balao}
            title="Atendimento"
            subtitle="podendo conhecer o ambiente"
          />
        </div>
      </div>
    </div>
  );
}

export default Services;
