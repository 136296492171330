import sx from "./Cardproducts.module.css";
import * as React from "react";
import Button from "@mui/material/Button";

function Cardproducts({ title, price, img, link }) {
  // console.log(img)
  
  const isDisabled = img === "/static/media/caixaabelha.c54cc9d22004d4f442f0.png"

  // const tempoaria somente para desativar um button que contem a imagem 

  return (
    <div className={sx.card}>
      <div>
        <h2>{title}</h2>
        <h5>{price}</h5>
      </div>
      <div>
        <img src={img} />
      </div>
      <div className={sx.btn}>
        <Button
          color="success"
          variant="contained"
          sx={{ width: "100%" }}
          href={link}
          target="_blank"
          disabled={isDisabled}
        >
          COMPRAR
        </Button>
      </div>
    </div>
  );
}

export default Cardproducts;
