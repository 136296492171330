import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Nav from  './components/section/Navbar'
import Head from './components/section/Head';
import Presentation from './components/section/Presentation';
import Services from './components/section/Services';
import Products from './components/section/Products';
import Maps from './components/section/Maps';
import Whats from './components/section/Whats';
import Footer from './components/section/Footer';
// import Buttonwhats from './components/elements/Buttonwhats';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Nav/>
    <Head/>
    <Presentation/>
    <Services/>
    <Products/>
    <Whats/>
    <Maps/>
    <Footer/>
     {/* <Buttonwhats/> */}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
