import sx from './Head.module.css'

function Head(){
    return (
        <div className={sx.head}>
            <h1>Meliponário <span>Lenoir Rieg</span></h1>
        </div>
    )
}

export default Head