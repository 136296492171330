import React, { useState, useEffect } from "react";
import sx from "./Presentation.module.css";
import abelhas01 from "../../images/abelhas01.png";
import abelhas02 from "../../images/abelhas02.png";
import abelhas03 from "../../images/abelhas03.png";
import abelhas04 from "../../images/abelhas04.png";
import abelhas05 from "../../images/abelhas05.png";

function Presentation() {
  const [index, setIndex] = useState(0);
  const images = [abelhas01,abelhas02,abelhas03,abelhas04,abelhas05]

  useEffect(()=>{
    const interval = setInterval(()=>{
      setIndex((prevIndex)=>(prevIndex + 1) %5)
    },4000)
    return()=> clearInterval(interval)
  },[])

  return (
    <div className={sx.container}>
      <div className={sx.text}>
        <p>
          Localizado na cidade de Guabiruba(SC) Bairro São Pedro, Rua Lorena,
          1807. Em um ambiente familiar rodeado pela natureza. Tem como objetivo
          a preservação de espécies, cuidando com o meio ambiente. O Brasil
          conta com aproximadamente 250 espécies de abelhas sem ferrão
          descritas. Algumas destas espécies são criadas para a produção de mel,
          que tem sido cada vez mais valorizado para fins gastronômicos por
          apresentar características únicas de acordo com a espécie de abelha
          sem ferrão manejada e as flores que as operárias usam para buscar o
          néctar. As abelhas sem ferrão fazem parte de uma importante parcela da
          natureza para polinização exclusiva de certas flores, com a formação
          de frutos e sementes. Sem a colaboração dessas abelhas, a reprodução
          de muitas plantas com flores seria impactada negativamente,
          ocasionando uma diminuição em suas populações, podendo inclusive
          chegar à extinção. Tornando se indispensável para fecundação das
          mesmas e que não sejam extintas. O meio ambiente agradece e a certeza
          que meus filhos e netos também vão agradecer no futuro próximo.
        </p>
      </div>
      <div className={sx.carousel}>
        <img
          src={images[index]}
          alt="Abelhas"
          className={sx.image}
        />
      </div>
    </div>
  );
}

export default Presentation;
