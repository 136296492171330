import sx from "./Cardservice.module.css";

function Cardservice({ img, title, subtitle }) {
  return (
    <div className={sx.card}>
      <div>
        <img src={img} />
      </div>
      <div>
        <h2>{title}</h2>
        <h5>{subtitle}</h5>
      </div>
    </div>
  );
}

export default Cardservice;